import { ImageProduct, TextProduct, Product, indexTypeEnum } from '../../types/types';
import TextProductCard from './TextProduct/textProduct';
import ImageProductCard from './ImageProduct/imageProduct';

const ProductCard = ({
  product,
  curIndexType,
  onFavourite,
  onAddToCart,
}: {
  product: Product;
  curIndexType: indexTypeEnum;
  onFavourite: (content: ImageProduct | TextProduct, type: string) => void;
  onAddToCart: (content: ImageProduct | TextProduct, type: string) => void;
}) => {
  if (curIndexType === indexTypeEnum.TEXT) {
    let textProduct = product as TextProduct;
    return TextProductCard({ product: textProduct, onFavourite });
  } else {
    let imageProduct = product as ImageProduct;
    return ImageProductCard({ product: imageProduct, onFavourite, onAddToCart });
  }
};

export default ProductCard;
