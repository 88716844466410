export function calculateItemsPerRow() {
  const gridContainer = document.querySelector('.result-display');

  if (!gridContainer) {
    return 0;
  }

  const gridWidth = gridContainer.getBoundingClientRect().width;

  const itemMinWidth = 300;
  const gap = 20;

  const itemsPerRow = Math.floor(gridWidth / (itemMinWidth + gap));

  return itemsPerRow;
}
