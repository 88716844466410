import { Button, Modal } from 'antd';
import { Filter } from '../../types/types';
import { useState } from 'react';
import FilterSelector from './filterSelector';
import { FilterFilled } from '@ant-design/icons';

import './filterSelectorModal.css';

type FilterSelectorModalProps = {
  filterableAttributes: string[];
  filterConfig: Record<string, { type: string; options: string[] }> | undefined;
  onChange: (filters: Filter[]) => void;
};

const FilterSelectorModal = ({
  filterableAttributes,
  filterConfig,
  onChange,
}: FilterSelectorModalProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button className="filter-modal-button" type="default" onClick={() => setOpen(true)}>
        {<FilterFilled />} Filters
      </Button>

      <Modal
        title="Filters"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
      >
        <div>
          <FilterSelector
            filterableAttributes={filterableAttributes}
            filterConfig={filterConfig}
            onChange={onChange}
          />
        </div>
      </Modal>
    </>
  );
};

export default FilterSelectorModal;
