import { useState, useEffect } from 'react';
import { Filter } from '../../types/types';
import { Button, Input, Select, Space } from 'antd';

import './filterSelector.css';

type FilterSelectorProps = {
  filterableAttributes: string[];
  filterConfig: Record<string, { type: string; options: string[] }> | undefined;
  onChange: (filters: Filter[]) => void;
};

const FilterSelector = ({ filterableAttributes, filterConfig, onChange }: FilterSelectorProps) => {
  const [filters, setFilters] = useState<Filter[]>([]);

  const defaultComparitors = ['=', '!=', '>', '<'];
  const restrictedComparitors = ['=', '!='];

  useEffect(() => {
    onChange(filters);
  }, [filters]);

  const addFilter = () => {
    const newFilter: Filter = {
      attribute: 'Please select...',
      comparitor: 'Please select...',
      value: '',
    };
    setFilters([...filters, newFilter]);
  };

  const editFilter = (index: number, attribute: string, comparitor: string, value: string) => {
    const newFilters = [...filters];

    // If the attribute is changed, reset the value and comparitor to defaults.
    if (newFilters[index].attribute !== attribute) {
      newFilters[index].comparitor = 'Please select...';
      newFilters[index].value = '';
    }

    newFilters[index].attribute = attribute;
    newFilters[index].comparitor = comparitor;
    newFilters[index].value = value;
    setFilters(newFilters);
  };

  const removeFilter = (index: number) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };

  return (
    <div className="filter-constructor">
      <div className="filters">
        {filters.map((filter, index) => {
          const comparitors =
            filterConfig !== undefined && filter.attribute in filterConfig
              ? restrictedComparitors
              : defaultComparitors;

          return (
            <div key={index} className="filter-entry">
              <Space direction="horizontal" size="small">
                <Select
                  style={{ width: 150 }}
                  value={filter.attribute}
                  onChange={(value) => {
                    editFilter(index, value, 'Please select...', '');
                  }}
                >
                  {filterableAttributes.map((attribute) => (
                    <Select.Option key={attribute} value={attribute}>
                      {attribute}
                    </Select.Option>
                  ))}
                </Select>

                <Select
                  style={{ width: 150 }}
                  value={filter.comparitor}
                  onChange={(value) => {
                    editFilter(index, filter.attribute, value, filter.value);
                  }}
                >
                  {comparitors.map((comparitor) => (
                    <Select.Option key={comparitor} value={comparitor}>
                      {comparitor}
                    </Select.Option>
                  ))}
                </Select>

                {filterConfig !== undefined && filter.attribute in filterConfig ? (
                  <Select
                    style={{ width: 150 }}
                    value={filter.value}
                    onChange={(value) => {
                      editFilter(index, filter.attribute, filter.comparitor, value);
                    }}
                  >
                    {filterConfig[filter.attribute].options.map((option) => (
                      <Select.Option key={option} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    value={filter.value}
                    onChange={(e) => {
                      editFilter(index, filter.attribute, filter.comparitor, e.target.value);
                    }}
                  />
                )}

                <Button
                  onClick={() => {
                    removeFilter(index);
                  }}
                >
                  Remove
                </Button>
              </Space>
            </div>
          );
        })}
      </div>
      <div className="add-filter">
        <Button onClick={addFilter}>Add Filter</Button>
      </div>
    </div>
  );
};

export default FilterSelector;
