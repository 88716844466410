import { API_BASE_URL } from '../constants';
import { AnyProduct } from '../types/types';

interface RecommendationRandomWalkRequest {
  itemID: string;
  layers: number;
  children: number;
  candidateLimit: number;
  demoID: string;
}

export const fetchRecommendationRandomWalk = async (params: RecommendationRandomWalkRequest) => {
  const response = await fetch(API_BASE_URL + '/recommendation_random_walk', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({
      itemID: params.itemID,
      layers: params.layers,
      children: params.children,
      candidateLimit: params.candidateLimit,
      demoID: params.demoID,
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  const results: AnyProduct[] = data.results;
  return data.results;
};
