import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { fetchProducts } from '../../slices/resultsSlice';
import { AppDispatch } from '../../store/store';
import { Button, Checkbox } from 'antd';
import ReactGA from 'react-ga4';
import { calculateItemsPerRow } from '../../utils/resultsPerRow';
import './searchBar.css';
import { Filter, ScoreModifier, indexTypeEnum } from '../../types/types';
import FilterSelectorModal from '../filterSelector/filterSelectorModal';
import ScoreModifierSelectorModal from '../scoreModifierSelector/scoreModifierSelectorModal';
import { setShowLiveRecommendations } from '../../slices/liveRecommendationsSlice';

type SearchBarProps = {
  enableMoreOf: boolean;
  enableLessOf: boolean;
  filterableAttributes: string[] | undefined;
  filterConfig: Record<string, { type: string; options: string[] }> | undefined;
  scoreModifierAttributes: string[] | undefined;
};

const SearchBar = ({
  enableMoreOf = true,
  enableLessOf = true,
  filterableAttributes = undefined,
  filterConfig = undefined,
  scoreModifierAttributes = undefined,
}: SearchBarProps) => {
  const [query, setQuery] = useState<string>('');
  const [moreOf, setMoreOf] = useState<string>('');
  const [lessOf, setLessOf] = useState<string>('');

  const [filters, setFilters] = useState<Filter[]>([]);
  const [scoreModifiers, setScoreModifiers] = useState<ScoreModifier[]>([]);

  const [searchAsYouType, setSearchAsYouType] = useState<boolean>(false);
  const [liveRecommendToggle, setLiveRecommendToggle] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const favourites = useSelector((state: RootState) => state.favourites.products);
  const customInstructions = useSelector(
    (state: RootState) => state.customInstructions.instructions,
  );
  const searchSettings = useSelector((state: RootState) => state.searchSettings);
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);
  const style = useSelector((state: RootState) => state.searchStyle.style);
  const advancedSettings = useSelector((state: RootState) => state.advancedSettings);
  const indexType = useSelector((state: RootState) => state.demoSelector.demoSettings.indexType);

  const handleSubmit = () => {
    dispatch(
      fetchProducts({
        query,
        moreOf,
        lessOf,
        filters,
        scoreModifiers,
        customInstructions,
        favourites,
        searchSettings,
        demoID,
        style,
        advancedSettings,
      }),
    );
    ReactGA.event('execute_search', {
      search_query: query,
      search_more_of: moreOf,
      search_less_of: lessOf,
      search_filters: JSON.stringify(filters),
      search_score_modifiers: JSON.stringify(scoreModifiers),
      search_custom_instructions: customInstructions,
      search_favourites: JSON.stringify(favourites),
      search_settings: JSON.stringify(searchSettings),
      search_demo_id: demoID,
      search_style: style,
      search_advanced_settings: JSON.stringify(advancedSettings),
    });
  };

  const handleQueryChange = (e: any) => {
    setQuery(e.target.value);
  };

  const handleMoreChange = (e: any) => {
    setMoreOf(e.target.value);
  };

  const handleLessChange = (e: any) => {
    setLessOf(e.target.value);
  };

  const handleFilterChange = (newFilters: Filter[]) => {
    setFilters(newFilters);
  };

  const handleScoreModifierChange = (newScoreModifiers: ScoreModifier[]) => {
    setScoreModifiers(newScoreModifiers);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const performSearch = useCallback(() => {
    if (!searchAsYouType) {
      return;
    }
    dispatch(
      fetchProducts({
        query,
        moreOf: null,
        lessOf: null,
        filters: null,
        scoreModifiers: null,
        customInstructions: null,
        favourites: [],
        searchSettings,
        demoID,
        style,
        advancedSettings: { ...advancedSettings, limit: calculateItemsPerRow() },
      }),
    );
  }, [query, dispatch]);

  useEffect(() => {
    if (!searchAsYouType) {
      return;
    }
    const timer = setTimeout(() => {
      performSearch();
    }, 200); // 500ms delay

    return () => clearTimeout(timer);
  }, [query, performSearch]);

  useEffect(() => {
    dispatch(setShowLiveRecommendations(liveRecommendToggle));
  }, [liveRecommendToggle]);

  return (
    <>
      <div className="search-bar">
        <input
          className="search-bar-input"
          type="text"
          placeholder="Search for this..."
          value={query}
          onChange={handleQueryChange}
          onKeyDown={handleKeyPress}
          // onKeyUp={handleTyping}
        />
        {enableMoreOf && (
          <input
            className="search-bar-input"
            type="text"
            placeholder="More of this..."
            value={moreOf}
            onChange={handleMoreChange}
            onKeyDown={handleKeyPress}
          />
        )}
        {enableLessOf && (
          <input
            className="search-bar-input"
            type="text"
            placeholder="Less of this..."
            value={lessOf}
            onChange={handleLessChange}
            onKeyDown={handleKeyPress}
          />
        )}
        {filterableAttributes && filterableAttributes.length > 0 && (
          <FilterSelectorModal
            filterableAttributes={filterableAttributes}
            filterConfig={filterConfig}
            onChange={handleFilterChange}
          />
        )}
        {scoreModifierAttributes && scoreModifierAttributes.length > 0 && (
          <ScoreModifierSelectorModal
            scoreModifierAttributes={scoreModifierAttributes}
            onChange={handleScoreModifierChange}
          />
        )}
        <Button className="search-button" onClick={handleSubmit} type="primary">
          Search
        </Button>
      </div>

      <Checkbox
        className="search-type-checkbox"
        onChange={(e) => setSearchAsYouType(e.target.checked)}
        checked={searchAsYouType}
      >
        Search as you type
      </Checkbox>
      {indexType === indexTypeEnum.MULTIMODAL_IMAGE_TEXT && (
        <Checkbox
          className="search-type-checkbox"
          onChange={(e) => setLiveRecommendToggle(e.target.checked)}
          checked={liveRecommendToggle}
        >
          Show live recommendations
        </Checkbox>
      )}
    </>
  );
};

export default SearchBar;
