import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setSearchStyle } from '../../slices/searchStyleSlice';
import { useEffect, useState } from 'react';
import { CustomFilter, QueryStyles } from '../../types/types';

const QueryPrefixer = () => {
  const [styleOptions, setStyleOptions] = useState<QueryStyles>([]);

  const dispatch = useDispatch();
  const style = useSelector((state: RootState) => state.searchStyle.style);

  const whatStyleAreYouAfterOptions = useSelector(
    (state: RootState) => state.demoSelector.demoSettings.availableStyles,
  );
  const handleChange = (value: string | null) => {
    dispatch(setSearchStyle(value));
  };

  function loadFromLocalStorage() {
    const storedFilters = localStorage.getItem('customFilters');
    if (storedFilters) {
      const filters: CustomFilter[] = JSON.parse(storedFilters);
      let filterOptions: QueryStyles = [];
      filterOptions = filterOptions.concat(
        filters.map((filter) => ({ value: filter.customFilterTemplate, label: filter.name })),
      );
      return filterOptions;
    }
    return [];
  }

  useEffect(() => {
    let newOptions: QueryStyles = [];
    newOptions = newOptions.concat(whatStyleAreYouAfterOptions);

    const customFilters = loadFromLocalStorage();
    newOptions = newOptions.concat(customFilters);

    setStyleOptions(newOptions);
  }, [whatStyleAreYouAfterOptions]);

  const handleClick = () => {
    let newOptions: QueryStyles = [];
    newOptions = newOptions.concat(whatStyleAreYouAfterOptions);

    const customFilters = loadFromLocalStorage();
    newOptions = newOptions.concat(customFilters);

    setStyleOptions(newOptions);
  };

  return (
    <div>
      <Select
        placeholder="Select a style (optional)"
        options={styleOptions}
        onChange={handleChange}
        onClick={handleClick}
        style={{ width: 200 }}
        value={style === null ? undefined : style}
      />
    </div>
  );
};

export default QueryPrefixer;
