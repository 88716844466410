import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useEffect, useState } from 'react';
import { ImageProduct } from '../../types/types';
import { fetchRecommendationRandomWalk } from '../../services/recommendationRandomWalkService';
import { ImageGrid } from './itemGrids';
import Loading from '../loading/loading';

type RecommendationWalkProps = {
  displayed: boolean;
};

const RecommendationWalk = ({ displayed }: RecommendationWalkProps) => {
  const [walk, setWalk] = useState<ImageProduct[]>([]);
  const [loading, setLoading] = useState(false);
  const { selectedProduct } = useSelector((state: RootState) => state.recommendations);
  const demoID = useSelector((state: RootState) => state.demoSelector.demoID);

  useEffect(() => {
    if (!selectedProduct || !displayed) {
      return;
    }
    setWalk([]);
    setLoading(true);

    const fetchData = async () => {
      try {
        const walkData = (await fetchRecommendationRandomWalk({
          itemID: selectedProduct.id,
          layers: 5,
          children: 2,
          candidateLimit: 100,
          demoID: demoID,
        })) as ImageProduct[];
        setWalk(walkData);
      } catch (error) {
        console.error('Failed to fetch recommendation walk:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedProduct, displayed]);

  if (!displayed || !selectedProduct) {
    return <></>;
  }

  return (
    <div>
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Loading />
        </div>
      )}
      <ImageGrid selectedId={selectedProduct?.id} products={walk} showRecommendButton={false} />
    </div>
  );
};

export default RecommendationWalk;
