import { Product, AnyProduct, ImageProduct } from '../../types/types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { addFavourite, removeFavourite } from '../../slices/favouritesSlice';
import { Image, notification } from 'antd';
import { HeartFilled, ExclamationCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import ProductCard from '../product/productCard';
import './resultsDisplay.css';
import Loading from '../loading/loading';
import { addToCart } from '../../slices/cartSlice';

const ResultsDisplay = () => {
  const indexType = useSelector((state: RootState) => state.demoSelector.demoSettings.indexType);
  const searchResults = useSelector((state: RootState) => state.results.products);
  const status = useSelector((state: RootState) => state.results.status);
  const error = useSelector((state: RootState) => state.results.error);
  const favouriteTerms = useSelector((state: RootState) => state.favourites.products);
  const cart = useSelector((state: RootState) => state.cart.products);

  const [api, contextHolder] = notification.useNotification();

  const isLoading = status === 'loading';

  const dispatch = useDispatch();

  const handleActionImageError = (content: AnyProduct) => () => {
    api['warning']({
      message: 'Warning!',
      description: (
        <>
          <ExclamationCircleOutlined style={{ color: '#020659' }} />
          <span className="notification-image-error">
            {' '}
            The image for {content.name} could not be loaded. Please try again later.
          </span>
        </>
      ),
      placement: 'bottomRight',
    });
    dispatch(removeFavourite(content));
  };

  const handleNewFavourite = (content: AnyProduct, type: string) => {
    if (favouriteTerms.length >= 10) {
      openFavouriteFullErrorNotification();
      return;
    }

    if (favouriteTerms.includes(content)) {
      openActionWarningNotification('favourite');
      return;
    }

    if (type === 'name' || type === 'text') {
      openNotification(<>{content}</>, 'favourite');
    } else {
      openNotification(
        <Image
          className="notification-image"
          src={(content as ImageProduct).image_url}
          alt={'Favourited Image'}
          width={200}
          height={200}
          onError={handleActionImageError(content)}
        />,
        'favourite',
      );
    }
    dispatch(addFavourite(content));
  };

  const handleAddToCart = (content: AnyProduct, type: string) => {
    if (cart.includes(content)) {
      openActionWarningNotification('atc');
      return;
    }

    if (type === 'name' || type === 'text') {
      openNotification(<>{content}</>, 'atc');
    } else {
      openNotification(
        <Image
          className="notification-image"
          src={(content as ImageProduct).image_url}
          alt={'ATC Image'}
          width={200}
          height={200}
          onError={handleActionImageError(content)}
        />,
        'atc',
      );
    }
    dispatch(addToCart(content));
  };

  const openNotification = (description: JSX.Element, action: 'atc' | 'favourite') => {
    const message = action === 'atc' ? 'Added to cart!' : 'Item favourited!';
    const icon =
      action === 'atc' ? (
        <ShoppingCartOutlined style={{ color: '#020659' }} />
      ) : (
        <HeartFilled style={{ color: '#020659' }} />
      );
    api.open({
      message: message,
      description: description,
      icon: icon,
      placement: 'bottomRight',
    });
  };

  const openActionWarningNotification = (actionType: 'atc' | 'favourite') => {
    const action = actionType === 'atc' ? 'added to cart' : 'favourited';
    api['warning']({
      message: 'Warning!',
      description: `This item is already ${action}!`,
      placement: 'bottomRight',
    });
  };

  const openFavouriteFullErrorNotification = () => {
    api['error']({
      message: 'Error!',
      description: 'The maximum number of favourites is 10.',
      placement: 'bottomRight',
    });
  };

  return (
    <div className="search-results">
      {contextHolder}
      <div className={`result-display ${isLoading ? 'loading' : ''}`}>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <div className="error">Error: {error}</div>
        ) : (
          searchResults.map((result: Product) => (
            <ProductCard
              key={result.id}
              product={result}
              onFavourite={handleNewFavourite}
              onAddToCart={handleAddToCart}
              curIndexType={indexType}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ResultsDisplay;
