import { useState } from 'react';
import { Checkbox, Col, Input, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  setAutoPrefix,
  setCustomPrefix,
  setImplicitMoreExpansion,
  setLimit,
  setApproximateSearch,
  setEfSearch,
  setLexicalRRF,
  setSearchType,
} from '../../slices/advancedSettingsSlice';

const AdvancedSettings = () => {
  const [efSearchError, setEfSearchError] = useState<Boolean>(false);
  const autoPrefix = useSelector((state: RootState) => state.advancedSettings.autoPrefix);
  const implicitMoreExpansion = useSelector(
    (state: RootState) => state.advancedSettings.implicitMoreExpansion,
  );
  const customPrefix = useSelector((state: RootState) => state.advancedSettings.customPrefix);
  const limit = useSelector((state: RootState) => state.advancedSettings.limit);
  const approximateSearch = useSelector(
    (state: RootState) => state.advancedSettings.approximateSearch,
  );
  const efSearch = useSelector((state: RootState) => state.advancedSettings.efSearch);
  const lexicalRRF = useSelector((state: RootState) => state.advancedSettings.lexicalRRF);
  const searchType = useSelector((state: RootState) => state.advancedSettings.searchType);

  const dispatch = useDispatch<AppDispatch>();

  const onPrefixToggle = (e: CheckboxChangeEvent) => {
    dispatch(setAutoPrefix(e.target.checked));
  };

  const onImplicitMoreExpansionToggle = (e: CheckboxChangeEvent) => {
    dispatch(setImplicitMoreExpansion(e.target.checked));
  };

  const onApproximateToggle = (e: CheckboxChangeEvent) => {
    dispatch(setApproximateSearch(e.target.checked));
  };

  const onSearchTypeToggle = (e: CheckboxChangeEvent) => {
    let newSearchType: 'semantic' | 'lexical' = e.target.checked ? 'lexical' : 'semantic';
    dispatch(setSearchType(newSearchType));

    if (newSearchType === 'lexical' && lexicalRRF) {
      dispatch(setLexicalRRF(false));
    }
  };

  const onLexicalRRFToggle = (e: CheckboxChangeEvent) => {
    dispatch(setLexicalRRF(e.target.checked));
  };

  const onCustomPrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomPrefix(e.target.value));
    if (e.target.value !== '') {
      dispatch(setAutoPrefix(false));
    }
  };

  const onLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newLimit = parseInt(e.target.value);
    if (newLimit > 150) {
      newLimit = 150;
    } else if (newLimit < 0) {
      newLimit = 1;
    }

    dispatch(setLimit(newLimit));
  };

  const onEfSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newEfSearch = parseInt(e.target.value);
    if (newEfSearch < 0) {
      newEfSearch = 0;
    }
    if (newEfSearch < limit) {
      setEfSearchError(true);
      newEfSearch = limit;
    } else {
      setEfSearchError(false);
    }

    dispatch(setEfSearch(newEfSearch));
  };

  return (
    <div>
      <Row>
        <Col span={6}></Col>
        <Col span={12}>
          <Checkbox onChange={onPrefixToggle} checked={autoPrefix} disabled={customPrefix !== ''}>
            Auto Prefix
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={6}></Col>
        <Col span={12}>
          <Checkbox onChange={onImplicitMoreExpansionToggle} checked={implicitMoreExpansion}>
            Implicit More Expansion
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={6}></Col>
        <Col span={12}>
          <Checkbox onChange={onApproximateToggle} checked={approximateSearch}>
            Approximate Search
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={6}></Col>
        <Col span={12}>
          <Checkbox onChange={onSearchTypeToggle} checked={searchType === 'lexical'}>
            Lexical Search
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={6}></Col>
        <Col span={12}>
          <Checkbox
            onChange={onLexicalRRFToggle}
            checked={lexicalRRF}
            disabled={searchType === 'lexical'}
          >
            Lexical RRF
          </Checkbox>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <div>Custom prefix:</div>
        </Col>
        <Col span={12}>
          <Input
            placeholder="Custom query prefix"
            value={customPrefix}
            onChange={onCustomPrefixChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <div>Limit:</div>
        </Col>
        <Col span={12}>
          <Input
            placeholder="Limit"
            value={limit}
            type="number"
            onChange={onLimitChange}
            min={1}
            max={150}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <div>efSearch:</div>
        </Col>
        <Col span={12}>
          <Input
            placeholder="efSearch"
            value={efSearch}
            type="number"
            onChange={onEfSearchChange}
            status={efSearchError ? 'error' : ''}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdvancedSettings;
